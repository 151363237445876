import type { ChainModifiers, Entry, EntryFieldTypes, EntrySkeletonType, LocaleCode } from 'contentful'

export interface TypeNavigationItemFields {
  title: EntryFieldTypes.Symbol
  href: EntryFieldTypes.Symbol
  target?: EntryFieldTypes.Symbol<'App' | 'Tab'>
  icon?: EntryFieldTypes.AssetLink
}

export type TypeNavigationItemSkeleton = EntrySkeletonType<TypeNavigationItemFields, 'navigationItem'>
export type TypeNavigationItem<Modifiers extends ChainModifiers, Locales extends LocaleCode> = Entry<
  TypeNavigationItemSkeleton,
  Modifiers,
  Locales
>

export function isTypeNavigationItem<Modifiers extends ChainModifiers, Locales extends LocaleCode>(
  entry: Entry<EntrySkeletonType, Modifiers, Locales>
): entry is TypeNavigationItem<Modifiers, Locales> {
  return entry.sys.contentType.sys.id === 'navigationItem'
}
