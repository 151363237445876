<script lang="ts" setup>
  import type { Entry } from 'contentful'
  import { isTypeNavigationItem, isTypeNavigationItemGroup, type TypeHeaderSkeleton } from '@/types/contentful'
  import { definedItems } from '@/utils/contentful'

  defineProps<{
    data: Entry<TypeHeaderSkeleton, 'WITHOUT_UNRESOLVABLE_LINKS', string>
  }>()

  const orderingStore = useOrderingStore()
  const { orderingAppNavTo, closePanel } = useOrderingAppNavigation()

  const ctaButtonLabel = computed<string>(() => {
    return orderingStore.cartItemCount > 0 ? `Order ⋅ ${orderingStore.cartItemCount}` : 'Order Now'
  })
</script>

<template>
  <CNavbarBase
    :header-logo-url="data.fields.logo?.fields.file?.url"
    :is-signed-in="orderingStore.user.isSignedIn"
    :mobile-menu-nav-links="data.fields.mobileItems"
    @menu-open="closePanel()"
  >
    <client-only>
      <template v-if="mdAndUp">
        <CNavAccountDropdown />

        <template v-for="item in definedItems(data.fields.desktopItems)" :key="item.title">
          <CNavDropdown v-if="isTypeNavigationItemGroup(item)" :data="item" />

          <div v-if="isTypeNavigationItem(item)" class="nav-link-wrapper">
            <CLink
              :href="item.fields.href"
              :is-embedded-app-link="item.fields.target === 'App'"
              :target="item.fields.target === 'Tab' ? '_blank' : undefined"
              class="nav-link"
            >
              {{ item.fields.title }}
            </CLink>
          </div>
        </template>
      </template>
    </client-only>

    <CButton
      role="menuitem"
      size="md"
      variant="primary"
      @click="orderingAppNavTo('/order')"
      @keydown.enter="orderingAppNavTo('/order')"
    >
      {{ ctaButtonLabel }}
    </CButton>
  </CNavbarBase>
</template>

<style lang="scss" scoped>
  .nav-link-wrapper {
    .nav-link {
      color: var(--color-base-black);
      padding: 8px 12px;
    }
  }
</style>
