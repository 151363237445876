<script lang="ts" setup>
  import type { Entry } from 'contentful'
  import type { TypeFooterSkeleton, TypeHeaderSkeleton } from '~/types/contentful'
  import CNavbarWithoutOrderingApp from '~/components/navigation/CNavbarWithoutOrderingApp/CNavbarWithoutOrderingApp.vue'
  import { SITE_ENVIRONMENTS } from '~/constants/siteEnvironment'

  const $config = useRuntimeConfig()

  useMetaRobots()

  const includeOrderingApp = $config.public.siteEnvironment === SITE_ENVIRONMENTS.AUSTRALIA

  /**
   * @description Fetch the data for the navigation components
   */
  const { data: navData } = useAsyncData(
    'navigation',
    async () => {
      const [header, footer] = await Promise.all([
        $fetch<Entry<TypeHeaderSkeleton, 'WITHOUT_UNRESOLVABLE_LINKS', string>>('/api/cms/header'),
        $fetch<Entry<TypeFooterSkeleton, 'WITHOUT_UNRESOLVABLE_LINKS', string>>('/api/cms/footer')
      ])

      return { header, footer }
    },
    {
      deep: false
    }
  )

  /**
   * Pass navData to homepage to dynamically generate Organisation Schema JSONld
   */
  useState('layoutData', () => shallowRef(navData))

  /**
   * @description Set the meta robots tag for SEO
   */
  function useMetaRobots() {
    const content = ref<string>($config.public.deployEnv !== 'production' ? 'noindex,nofollow' : 'all')

    useHead({
      meta: [{ name: 'robots', content }]
    })
  }
</script>

<template>
  <suspense>
    <template #default>
      <div class="layout-default">
        <!-- Header -->
        <header v-if="navData?.header">
          <CNavbarWithOrderingApp v-if="includeOrderingApp" :data="navData.header" />
          <CNavbarWithoutOrderingApp v-else :data="navData.header" />
        </header>

        <!-- Page content -->
        <main>
          <div class="page-content">
            <slot />
          </div>

          <slot name="extras" />
        </main>

        <!-- Footer -->
        <footer>
          <CFooter v-if="navData?.footer" :data="navData.footer" />
        </footer>
      </div>
    </template>

    <template #fallback>
      <div class="layout-default">
        <main>
          <CPageSpinner />
        </main>
      </div>
    </template>
  </suspense>
</template>

<style lang="scss" scoped>
  .layout-default {
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    background-color: var(--color-secondary-paper-lightest);
  }

  header {
    background-color: var(--color-secondary-paper-light);
    height: 72px; // 48px + 24px (padding)

    @include screenMdAndUp {
      height: 118px; // 86px + 32px (padding)
    }
  }

  main {
    flex: 1;
    display: flex;
    flex-direction: column;

    .page-content {
      background: var(--color-secondary-paper-lightest);
      flex: 1;
      display: flex;
      flex-direction: column;
    }
  }

  footer {
    background-color: var(--color-secondary-paper-light);

    min-height: 420px;

    @include screenMdAndUp {
      min-height: 326px; // 198px + 128px (padding)
    }
  }
</style>
