<script lang="ts" setup>
  import type { Entry } from 'contentful'
  import { APP_STORE } from '@/constants/url'
  import type { TypeFooterSkeleton } from '@/types/contentful'
  import { definedItems } from '@/utils/contentful'

  const props = defineProps<{
    data: Entry<TypeFooterSkeleton, 'WITHOUT_UNRESOLVABLE_LINKS', string>
  }>()
</script>

<template>
  <div class="c-footer">
    <div class="row-start">
      <div class="col-start">
        <div v-for="group in definedItems(props.data.fields.items)" :key="group.sys.id" class="footer-group-links">
          <span class="footer-group-links-title">{{ group.fields.title }}</span>
          <CLink
            v-for="link in definedItems(group.fields.items)"
            :key="link.fields.title"
            :aria-label="link.fields.title"
            :href="link.fields.href"
            :is-embedded-app-link="link.fields.target === 'App'"
            :target="link.fields.target"
            class="footer-link-text"
          >
            <nuxt-img
              v-if="link.fields.icon?.fields.file"
              :alt="link.fields.icon.fields.description"
              :src="link.fields.icon.fields.file?.url"
              class="footer-link-icon"
              loading="lazy"
              provider="contentful"
              quality="100"
            />
            <span>{{ link.fields.title }}</span>
          </CLink>
        </div>
      </div>

      <div class="col-end">
        <div class="social-media-links">
          <template v-for="link in definedItems(props.data?.fields.socials)" :key="link.id">
            <CLink
              v-if="link.fields.icon"
              :aria-label="link.fields.title"
              :href="link.fields.href"
              :is-embedded-app-link="link.fields.target === 'App'"
              :target="link.fields.target"
              class="social-media-link"
            >
              <nuxt-img
                v-if="link.fields.icon.fields.file"
                :alt="link.fields.icon.fields.description"
                :src="link.fields.icon.fields.file?.url"
                class="social-media-icon"
                loading="lazy"
                provider="contentful"
                quality="100"
              />
            </CLink>
          </template>
        </div>

        <div class="app-store-links">
          <nuxt-link :href="APP_STORE.APPLE_STORE" height="52" target="_blank" width="180">
            <icon-appstore-download-apple aria-label="Download on the App Store" class="app-store-image" />
          </nuxt-link>
          <nuxt-link :href="APP_STORE.GOOGLE_PLAY" height="52" target="_blank" width="180">
            <icon-appstore-download-google aria-label="Get it on Google Play" class="app-store-image" />
          </nuxt-link>
        </div>
      </div>
    </div>

    <div class="row-end">
      <span v-if="data.fields.disclaimer" class="footer-disclaimer">
        {{ data.fields.disclaimer }}
      </span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .c-footer {
    display: flex;
    flex-direction: column;
    row-gap: 32px;

    background-color: var(--color-secondary-paper-light);
    color: var(--color-text-footer-link);
    padding: 48px 16px;

    max-width: var(--breakpoint-xl);
    margin: 0 auto;
    width: 100%;

    min-height: 300px;

    @include screenMdAndUp {
      min-height: 326px;
    }

    .row-start {
      display: flex;
      flex-direction: column;
      gap: 32px;
    }

    .row-end {
      width: 100%;
    }

    .col-start {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      row-gap: 24px;
      column-gap: 32px;
    }

    .col-end {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      gap: 32px;
    }

    @include screenMdAndUp {
      padding: 64px 32px;

      .row-start {
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
      }

      .col-start {
        flex-direction: row;
      }

      .col-end {
        align-items: flex-end;
      }
    }
  }

  .footer-group-links {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    line-height: 1;

    .footer-group-links-title {
      @include typography-body-large;
      color: var(--color-base-black);
      font-weight: 700;
      text-transform: uppercase;
    }

    .footer-link-text {
      @include typography-body;

      display: flex;
      align-items: center;
      column-gap: 4px;

      span {
        color: var(--color-text-footer-link);
      }
    }

    .footer-link-icon {
      width: 24px;
      height: 24px;
    }
  }

  .social-media-links {
    display: flex;
    column-gap: 32px;

    .social-media-link {
      line-height: 0;

      .social-media-icon {
        cursor: pointer;
        width: 24px;
        height: 24px;
      }
    }
  }

  .app-store-links {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .app-store-image {
      cursor: pointer;
    }

    @include screenMdAndUp {
      flex-direction: row;
      gap: 32px;
    }
  }

  .footer-disclaimer {
    @include typography-body-small;
    display: block;
    text-align: center;
  }
</style>
