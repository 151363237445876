<script lang="ts" setup>
  import { accountLinks } from '@/constants/navigation'
  import { type DropdownRefValue, useDropdownKeyboardEvents } from '@/components/common/CDropdown/CDropdown.composables'

  const orderingStore = useOrderingStore()

  const dropdownRef = ref<DropdownRefValue>(null)
  const { setDropdownItemRef, handleItemClick, focusFirstDropdownItem, focusNextElement, focusPreviousElement } =
    useDropdownKeyboardEvents(dropdownRef)
</script>

<template>
  <client-only>
    <CDropdown
      ref="dropdownRef"
      class="nav-link-dropdown"
      hide-chevron
      hide-underline
      @keydown.down.prevent="focusFirstDropdownItem"
    >
      <template #trigger>
        <icon-grilld-account
          v-if="orderingStore.user.isSignedIn"
          aria-haspopup="menu"
          aria-label="account"
          class="dropdown-trigger"
          height="32px"
          role="group"
          width="32px"
        />
        <icon-ionic-person
          v-else
          aria-haspopup="menu"
          aria-label="account"
          class="dropdown-trigger-icon"
          height="32px"
          role="group"
          width="32px"
        />
      </template>
      <template #list>
        <ul>
          <li
            v-for="(item, index) in orderingStore.user.isSignedIn ? accountLinks.relish : accountLinks.guest"
            :key="index"
            :ref="(el) => setDropdownItemRef(el, index)"
            class="nav-dropdown-link"
            role="menuitem"
            tabindex="0"
            @keydown.down.exact="focusNextElement($event, index)"
            @keydown.up.exact="focusPreviousElement($event, index)"
            @keydown.enter.exact="handleItemClick(index)"
          >
            <CLink :href="item.href" :is-embedded-app-link="item.isEmbeddedAppLink" class="dropdown-link">
              {{ item.title }}
            </CLink>
          </li>
        </ul>
      </template>
    </CDropdown>
  </client-only>
</template>

<style lang="scss" scoped>
  .dropdown-trigger {
    cursor: pointer;
  }

  .nav-dropdown-link {
    margin: 0;

    .dropdown-link {
      display: block;
      padding: 16px;
      color: var(--color-base-white);
    }
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      margin: 0;

      .dropdown-link {
        display: block;
        padding: 16px;
      }
    }

    li:not(:last-child) {
      border-bottom: 1px solid var(--color-secondary-green-hover);
    }

    li:first-child {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }

    li:last-child {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
</style>
