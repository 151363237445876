import type { ComponentPublicInstance, Ref } from 'vue'
import type CDropdown from './CDropdown.vue'

/**
 * @description Ref value for CDropdown component
 * @example const dropdownRef = ref<DropdownRefValue>(null)
 */
export type DropdownRefValue = InstanceType<typeof CDropdown> | null

/**
 * @description Handle template refs for items in the dropdown list
 */
export function useDropdownKeyboardEvents(dropdownRef: Ref<DropdownRefValue>) {
  const dropdownItemRefs = ref<Record<number, HTMLElement>>({})

  /**
   * @description Set the ref for the dropdown item
   * @param el - HTMLElement
   * @param index - index of the dropdown item
   * @example <li v-for="(item, index) in dropdownItems" :ref="(el) => setDropdownItemRef(el, index)">Dropdown Item</li>
   */
  function setDropdownItemRef(el: Element | ComponentPublicInstance | null, index: number) {
    dropdownItemRefs.value[index] = el as HTMLElement
  }

  /**
   * @description Focus the first item in the dropdown list
   * @event KeyboardEvent - e.g. keydown.down on parent element
   */
  function focusFirstDropdownItem() {
    dropdownItemRefs.value[0].focus()
  }

  /**
   * @description Focus the last item in the dropdown list
   * @event KeyboardEvent - e.g. keydown.up on first item in list
   */
  function focusLastDropdownItem() {
    dropdownItemRefs.value[Object.keys(dropdownItemRefs.value).length - 1].focus()
  }

  /**
   * @description Focus the next item in the dropdown list
   * @event KeyboardEvent - e.g. keydown.down on current item
   * @param event - KeyboardEvent
   * @param index - index of the current item
   */
  function focusNextElement(event: KeyboardEvent, index: number) {
    if (index < Object.keys(dropdownItemRefs.value).length - 1) {
      const nextDropdownItem = dropdownItemRefs.value[index + 1]

      nextDropdownItem.focus()
    } else {
      focusFirstDropdownItem()
    }

    event.stopPropagation()
    event.preventDefault()
  }

  /**
   * @description Focus the previous item in the dropdown list
   * @event KeyboardEvent - e.g. keydown.up on current item
   * @param event - KeyboardEvent
   * @param index - index of the current item
   */
  function focusPreviousElement(event: KeyboardEvent, index: number) {
    if (index > 0) {
      const previousDropdownItem = dropdownItemRefs.value[index - 1]

      if (previousDropdownItem) {
        previousDropdownItem.focus()
      }
    } else {
      focusLastDropdownItem()
    }

    event.preventDefault()
  }

  /**
   * @description Handle click event on dropdown item
   * @event MouseEvent/KeyboardEvent - e.g. click/keydown.enter on dropdown item
   * @param index - index of the clicked item
   */
  function handleItemClick(index: number) {
    const dropdownItem = dropdownItemRefs.value[index].firstChild as HTMLElement

    if (dropdownRef.value && dropdownItem) {
      dropdownRef.value.close()
      dropdownItem.click()
    }
  }

  return {
    dropdownItemRefs,
    setDropdownItemRef,
    focusFirstDropdownItem,
    focusLastDropdownItem,
    focusNextElement,
    focusPreviousElement,
    handleItemClick
  }
}
